/** 状态枚举 */
export const STATE_ENUM = {
  used: {
    value: 1,
    label: "启用",
  },
  disable: {
    value: 2,
    label: "禁用",
  },
};

/** 车类别 */
export const CAR_CATEGORY_ENUM = [
  { label: "全部", value: "" },
  {
    value: "铁皮车",
    label: "铁皮车",
  },
  {
    value: "冷藏车",
    label: "冷藏车",
  },
  {
    value: "高栏车",
    label: "高栏车",
  },
];

/** 车型枚举 */
export const VEHICLE_MODEL = [
  { label: "全部", value: "" },
  {
    value: "4.2米以下",
    label: "4.2米以下",
  },
  {
    value: "4.2米",
    label: "4.2米",
  },
  {
    value: "5.2米",
    label: "5.2米",
  },
  {
    value: "6.8米",
    label: "6.8米",
  },
  {
    value: "7.7米",
    label: "7.7米",
  },
  {
    value: "9.6米",
    label: "9.6米",
  },
  {
    value: "13米及以上",
    label: "13米及以上",
  },
];

/** 是否合车 */
export const IS_COMBINED_CAR = [
  { label: "全部", value: "" },
  {
    value: "1",
    label: "是",
  },
  {
    value: "0",
    label: "否",
  },
];

/** 扣款 */
export const DEDUCTION_ENUM = [
  { label: "全部", value: "" },
  {
    value: "1",
    label: "已扣款",
  },
  {
    value: "0",
    label: "未扣款",
  },
];

/** 审核 */
export const EXAMINE_ENUM = [
  { label: "全部", value: "" },
  {
    value: "1",
    label: "已审核",
  },
  {
    value: "0",
    label: "未审核",
  },
];
