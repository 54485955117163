<template>
  <el-table
    ref="costReviewTable"
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="55" fixed="left">
    </el-table-column>
    <el-table-column
      prop="date"
      align="center"
      min-width="120"
      label="发车日期"
      show-overflow-tooltip
      fixed="left"
    >
    </el-table-column>
    <el-table-column prop="fleet_name" fixed="left" align="center" label="队别">
    </el-table-column>
    <el-table-column
      prop="logistics_name"
      align="center"
      label="城市仓"
      fixed="left"
      min-width="120"
    >
    </el-table-column>
    <el-table-column prop="vehicle_model_name" align="center" label="车型">
    </el-table-column>
    <el-table-column prop="is_merge_txt" align="center" label="是否合车">
    </el-table-column>
    <el-table-column
      prop="vehicle_model_type_name"
      align="center"
      min-width="150"
      label="车类别"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="front_license_plates_no"
      align="center"
      label="车牌(车头)"
    >
    </el-table-column>
    <el-table-column
      prop="after_license_plates_no"
      align="center"
      label="车牌(车尾)"
    >
    </el-table-column>
    <el-table-column
      prop="self_purchase_tonnage"
      align="center"
      label="自采吨位"
    >
    </el-table-column>
    <el-table-column prop="online_tonnage" align="center" label="线上吨位">
    </el-table-column>
    <el-table-column prop="all_tonnage" align="center" label="合计吨位">
    </el-table-column>
    <el-table-column
      prop="is_platform_dispatch_txt"
      align="center"
      label="平台调车"
    >
    </el-table-column>
    <el-table-column prop="is_platform_fleet_txt" align="center" label="自装卸">
    </el-table-column>
    <el-table-column prop="remark" align="center" label="备注">
    </el-table-column>
    <el-table-column prop="handling_cost" align="center" label="装卸费应扣款">
    </el-table-column>
    <el-table-column prop="merge_fare" align="center" label="合车费应扣款">
    </el-table-column>
    <el-table-column prop="site_cost" align="center" label="场地费应扣款">
    </el-table-column>
    <el-table-column prop="plank_fee" align="center" label="用板费应扣款">
    </el-table-column>
    <el-table-column prop="use_plate_num" align="center" label="用板数量">
    </el-table-column>
    <el-table-column
      prop="real_handling_cost"
      align="center"
      label="装卸费实际扣款"
    >
      <template slot-scope="scope">
        <el-tooltip
          class="item"
          :class="
            Number(scope.row.real_handling_cost) !=
            Number(scope.row.handling_cost)
              ? 'textRed'
              : ''
          "
          :disabled="
            Number(scope.row.real_handling_cost) ==
              Number(scope.row.handling_cost) || !scope.row.change_desc
          "
          :content="scope.row.change_desc"
          placement="top-start"
        >
          <span> {{ scope.row.real_handling_cost || "0" }}</span>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column
      prop="real_merge_fare"
      align="center"
      label="合车费实际扣款"
    >
      <template slot-scope="scope">
        <el-tooltip
          class="item"
          :class="
            Number(scope.row.real_merge_fare) != Number(scope.row.merge_fare)
              ? 'textRed'
              : ''
          "
          :disabled="
            Number(scope.row.real_merge_fare) == Number(scope.row.merge_fare) ||
            !scope.row.change_desc
          "
          :content="scope.row.change_desc"
          placement="top-start"
        >
          <span> {{ scope.row.real_merge_fare || "0" }}</span>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column
      prop="real_site_cost"
      align="center"
      label="场地费实际扣款"
    >
      <template slot-scope="scope">
        <el-tooltip
          class="item"
          :class="
            Number(scope.row.real_site_cost) != Number(scope.row.site_cost)
              ? 'textRed'
              : ''
          "
          :disabled="
            Number(scope.row.real_site_cost) == Number(scope.row.site_cost) ||
            !scope.row.change_desc
          "
          :content="scope.row.change_desc"
          placement="top-start"
        >
          <span> {{ scope.row.real_site_cost || "0" }}</span>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column
      prop="real_plank_fee"
      align="center"
      label="用板费实际扣款"
    >
      <template slot-scope="scope">
        <el-tooltip
          class="item"
          :class="
            Number(scope.row.real_plank_fee) != Number(scope.row.plank_fee)
              ? 'textRed'
              : ''
          "
          :disabled="
            Number(scope.row.real_plank_fee) == Number(scope.row.plank_fee) ||
            !scope.row.change_desc
          "
          :content="scope.row.change_desc"
          placement="top-start"
        >
          <span> {{ scope.row.real_plank_fee || "0" }}</span>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column
      prop="is_audit_txt"
      align="center"
      label="审核状态"
      fixed="right"
    >
    </el-table-column>
    <el-table-column
      prop="is_deduct_txt"
      align="center"
      label="扣款状态"
      fixed="right"
    ></el-table-column>
    <el-table-column
      prop="tdc_owner_status"
      align="center"
      label="操作"
      width="120"
      fixed="right"
    >
      <template slot-scope="scope">
        <!--  待审核 & 未扣款 -->
        <div v-if="scope.row.is_audit == 0 && scope.row.is_deduct == 0">
          <el-button type="text" @click="onHandleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" @click="onHandleStatus(scope.row, 1)"
            >审核</el-button
          >
        </div>
        <!--  已审核 & 未扣款 -->
        <div v-if="scope.row.is_audit == 1 && scope.row.is_deduct == 0">
          <el-button type="text" @click="onHandleStatus(scope.row, 2)"
            >取消审核</el-button
          >
          <el-button
            type="text"
            @click="onHandleStatus(scope.row, 3)"
            v-if="is_deduct"
            >扣款</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { STATE_ENUM } from "../../utils/enum/index";
import { postLogisticsBasicFeeSettlementCancel } from "@/api/general/cost.js";
export default {
  name: "TableList",
  props: ["tableData", "is_deduct"],
  data() {
    return {
      STATE_ENUM,
    };
  },
  methods: {
    // 批量选择
    handleSelectionChange(val) {
      console.log(val, "eee");
      let tmpIds = [];
      if (val.length > 0) {
        val.map((item) => {
          tmpIds.push(item.id);
        });
      }
      let disabledAudit = val.some((obj) => obj.is_audit == 1);
      let disabledKou = val.some(
        (obj) => obj.is_deduct == 1 || obj.is_audit == 0
      );
      console.log(disabledAudit, disabledKou, "eee");
      this.$emit("getSelectId", tmpIds, disabledAudit, disabledKou);
    },
    // 清空选择
    clearSelect() {
      this.$refs.costReviewTable.clearSelection();
    },
    // 编辑
    onHandleEdit(e) {
      this.$emit("onHandleEdit", e);
    },
    // 取消审核
    async postAjaxLogisticsBasicFeeSettlementCancel(id) {
      try {
        const res = await postLogisticsBasicFeeSettlementCancel({
          logistics_fee_id: id,
        });
        console.log(res, "res==");
        this.$message({
          type: "success",
          message: "取消审核成功",
        });
        this.$emit("submit-form");
      } catch (error) {
        console.log(error, "postLogisticsBasicFeeSettlementCancel");
      }
    },
    /**
     * 修改状态
     */
    onHandleStatus(row, type) {
      console.log(row, "www");
      let tmpText = "";
      if (type == 1) {
        tmpText = "是否审核么";
      } else if (type == 2) {
        tmpText = "是否取消审核么";
      } else if (type == 3) {
        tmpText = "是否扣款";
      }

      this.$confirm(tmpText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          // 审核
          if (type == 1) {
            this.$emit("postAjaxLogisticsBasicFeeSettlementAudit", [row.id]);
          } else if (type == 2) {
            // 取消审核
            this.postAjaxLogisticsBasicFeeSettlementCancel(row.id);
          } else if (type == 3) {
            // 扣款
            this.$emit("postAjaxLogisticsBasicFeeSettlementDeduct", [row.id]);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.textRed {
  color: red;
}
</style>
