var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "编辑",
            visible: _vm.dialogFormVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents1" },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "供货日" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.date || "-") + " "),
                  ]),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 2 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "城市仓" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.detail.logistics_name || "-") + " "
                    ),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "合车城市仓" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.detail.merge_logistics_name || "-") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "车型" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.vehicle_model_name || "-")),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "车牌（车头)" } },
                    [_vm._v(_vm._s(_vm.detail.front_license_plates_no || "-"))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "车牌（车尾)" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.detail.after_license_plates_no || "-") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "线上吨位" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.online_tonnage || "-")),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "自采吨位" } }, [
                    _vm._v(_vm._s(_vm.detail.self_purchase_tonnage || "-")),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "总计" } }, [
                    _vm._v(_vm._s(_vm.detail.all_tonnage || "-")),
                  ]),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                    _vm._v(_vm._s(_vm.detail.remark || "-")),
                  ]),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "用板应收款" } },
                    [_vm._v(" " + _vm._s(_vm.detail.plank_fee) + "元")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "用板费实际收款" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: (val) =>
                            _vm.handleInput(val, "real_plank_fee"),
                        },
                        model: {
                          value: _vm.detail.real_plank_fee,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "real_plank_fee", $$v)
                          },
                          expression: "detail.real_plank_fee",
                        },
                      }),
                      _vm._v("元 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "合车费应收款" } },
                    [_vm._v(" " + _vm._s(_vm.detail.merge_fare) + "元 ")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "合车费实际收款" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: (val) =>
                            _vm.handleInput(val, "real_merge_fare"),
                        },
                        model: {
                          value: _vm.detail.real_merge_fare,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "real_merge_fare", $$v)
                          },
                          expression: "detail.real_merge_fare",
                        },
                      }),
                      _vm._v("元 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "装卸费应收款" } },
                    [_vm._v(" " + _vm._s(_vm.detail.handling_cost) + "元")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "装卸费实际收款" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: (val) =>
                            _vm.handleInput(val, "real_handling_cost"),
                        },
                        model: {
                          value: _vm.detail.real_handling_cost,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "real_handling_cost", $$v)
                          },
                          expression: "detail.real_handling_cost",
                        },
                      }),
                      _vm._v("元 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "场地费应收款" } },
                    [_vm._v(" " + _vm._s(_vm.detail.site_cost) + "元")]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "场地费实际收款" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: (val) =>
                            _vm.handleInput(val, "real_site_cost"),
                        },
                        model: {
                          value: _vm.detail.real_site_cost,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "real_site_cost", $$v)
                          },
                          expression: "detail.real_site_cost",
                        },
                      }),
                      _vm._v("元 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 2 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "扣款备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入扣款备注",
                          type: "textarea",
                          rows: 3,
                          maxlength: "128",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.detail.change_desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "change_desc", $$v)
                          },
                          expression: "detail.change_desc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submitFrom },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }